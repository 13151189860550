import React from 'react';
import './App.css';
import Home from './pages/Home';
import ContactUs from './pages/ContactUs';
import NotFound from './pages/NotFound';
import AboutUs from './pages/AboutUs';
import FAQ from './pages/FAQ';
import TopNavbar from './components/Navbar';
import Footer from './components/Footer';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";



function App() {
  return (
    <div className="App">
      <TopNavbar />
      <>
        <Router basename={"/"}>
            <Routes>
                <Route
                    path="/"
                    element={<Home/>}
                />
                <Route
                    path="*"
                    element={<NotFound/>}
                />
            </Routes>
        </Router>
        </>
      <Footer/>
    </div>
  );
}

export default App;
