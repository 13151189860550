import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin , faInstagram } from '@fortawesome/free-brands-svg-icons';
import privacy_pol from '../asserts/Files/fms-privacy-policy.pdf'
import company_profile from '../asserts/Files/FMS-Company-Profile.pdf'
import "./FooterStyles.css"


function Footer() {
  return (
    <footer style={{ backgroundColor: '#FFFFFF' }} className="py-3 text-light">
      <Container>
        <Row>
          <Col xs={12} sm={6} md={3}>
            <br/><br/>
            <ul className="list-unstyled mb-0 text-muted">
              <li><a href="mailto: sales@fraudmitigationsolutions.co.za">sales@fraudmitigationsolutions.co.za</a></li>
              <li><a href="mailto: admin@fraudmitigationsolutions.co.za">admin@fraudmitigationsolutions.co.za</a></li>
              <li><a href="mailto: support@fraudmitigationsolutions.co.za">support@fraudmitigationsolutions.co.za</a></li>
            </ul>
            <br/>
            <ul className="list-unstyled mb-0 text-muted">
              <li><a href={company_profile}  >About F.M.S</a></li>
              <li><p>Cape Town, 7441</p></li>
            </ul>
          </Col>       
          <Col xs={12} sm={6} md={3}>
            <br/>
            <br/>
            <ul className="list-unstyled mb-0 text-muted top">
              <li><a href={privacy_pol}  >Privacy Policy</a></li>
            </ul>               
          </Col>
          <Col>              
            <ul className="list-unstyled mb-0 text-muted top">
              <p className="mb-0 text-muted top"></p>
              <br/><br/>
              <li><a href="https://www.instagram.com/fraudmitigationsolutions/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} color='#ffb06d' size="2x" /></a></li>
              <br/>
              <li><a href="https://fraudmitigationsolutions.co.za/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} color='#ffb06d' size="2x"/></a></li>
            </ul>
          </Col>
          <Col xs={12} sm={6} md={3}>
          <h5 className="mb-0 text-muted top">Fraud mitigation solutions</h5>
          <br/>
            <p className="mb-0 text-muted top">Streamline compliance and mitigate risk with our comprehensive vetting solutions, including KYC, KYE, and KYB. Ensure regulatory peace of mind and protect your business from fraud and financial crime.</p>
          </Col>
        </Row>
        <Row>
           <Col xs={12} sm={12} md={12}>
            <p className="mb-0 text-muted top">copyright &copy; {new Date().getFullYear()} fraud mitigation solutions.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer