import { useNavigate } from 'react-router-dom'; // For routing
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function NotfoundComponent() {

  const navigate = useNavigate(); // Hook for navigation

  const handleHomeClick = () => {
    navigate('/'); // Redirect to the home page path
  };
  
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1 className='four'>404</h1>
            <h1>Page not found. Even this risk we mitigate</h1>
          </Col>
        </Row>
        <Row>
          <Button variant="outline-info" size="lg" onClick={handleHomeClick}>
            Back to home page
          </Button>
        </Row>
      </Container>
    </>
  )
}

export default NotfoundComponent