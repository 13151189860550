import React from 'react';
import CarouselBar from '../components/Carousel';
import Form from '../components/ContactComponent';




const c1 = 'Get in Touch';
const t1 = 'How can we help you?';
const c2 = 'We\'re here for you';
const t2 = 'Utilizing innovative methods to confirm applicant identities while maintaining strict data security and privacy.';
const c3 = 'Reach out';
const t3 = 'Accountable screening for criminal offenses on national databases, adhering to all legal regulations.';
const working1 = require('../asserts/images/carouselPictures/Caro3.png');
const working2 = require('../asserts/images/carouselPictures/Caro8.png');
const working3 = require('../asserts/images/carouselPictures/Caro7.png');
 


function contactUs() {

  return (
    <div >
      <div>
        <CarouselBar caption1={c1} text1={t1} image1={working1} caption2={c2} text2={t2} image2={working2} caption3={c3} text3={t3} image3={working3}/>
        
        <Form/>
      </div>
    </div>
  );
}

export default contactUs;