import React from 'react';
import './Home.css';
import LoginComponent from '../components/LoginComponent';

function Soon() {

  
  return (
    <>
      <LoginComponent />
    </>
  );
}

export default Soon;