import React from 'react';
import AccordionComponent from '../components/AccordionComponent';
import CarouselBar from '../components/Carousel';
import CardComponent from '../components/Card';

const working1 = require('../asserts/images/carouselPictures/Caro1.png');
const working2 = require('../asserts/images/carouselPictures/Caro2.png');
const working3 = require('../asserts/images/carouselPictures/working3.jpg');

const q0 = "Do you maintain confidentiality throughout the process?";
const a0 = "Absolutely. We adhere to strict data security and privacy regulations to protect all information..";
const q1 = "Where do you get your data from?";
const a1 = "We obtain data from a variety of reputable sources, including government agencies, educational institutions, and public records databases";
const q2 = "What is the process for a background check with FMS?";
const a2 = "The process is simple and efficient. You'll provide us with the candidate's information and choose the desired checks. We handle the rest, delivering timely and accurate results";
const q3 = "How long do background checks typically take?";
const a3 = "Turnaround times can vary depending on the complexity of the checks. We'll provide an estimated timeframe upon request.";
const q4 = "Is FMS a reputable company?";
const a4 = "Yes. We are a 100% Black-owned company committed to integrity and accuracy in every background check.";
const q5 = "How secure is your cloud-based solution?";
const a5 = "We take data security very seriously. Our cloud-based solution utilizes industry-standard security measures, including encryption, access controls, and regular security audits";
const q6 = "Do you sell our data?";
const a6 = "Absolutely not. We treat your data with the utmost confidentiality and security. Your information is used solely for the purpose of conducting background checks on your behalf.";
const q7 = "What if I have a question not listed here?";
const a7 = "Please don't hesitate to contact us at support@fraudmitigationsolutions.co.za. We're happy to answer any questions you may have.";

const c1 = 'FAQ';
const t1 = 'Frequently asked questions';
const c2 = 'FAQ';
const t2 = 'Frequently asked questions';
const c3 = 'FAQ';
const t3 = 'Frequently asked questions';

const title = 'Fraud Mitigation Solutions unwavering pillars';
const text1 = "Fraud Mitigation Solutions";
const text2 = 'At Fraud Mitigation Solutions (FMS), we stand on three unwavering pillars: integrity, accountability, and innovation. We are committed to conducting our business with the highest ethical standards, taking full responsibility for our work, and constantly seeking new solutions to empower businesses and build trust in the workplace'
const Cube4 = require('../asserts/images/carouselPictures/Cube4.png');


function FAQ() {
  return (
    <div>
      <CarouselBar caption1={c1} text1={t1} image1={working1} caption2={c2} text2={t2} image2={working2} caption3={c3} text3={t3} image3={working3}/>
      <br/>
      <AccordionComponent q0={q0} a0={a0} q1={q1} a1={a1} q2={q2} a2={a2} q3={q3} a3={a3} q4={q4} a4={a4} q5={q5} a5={a5} q6={q6} a6={a6} q7={q7} a7={a7}/>
      <br/>
      <CardComponent title={title} text1={text1} text2={text2} imageUrl={Cube4} />
    </div>
  )
}

export default FAQ