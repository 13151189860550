import React from 'react';
import './LoginComponent.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useState, useEffect } from 'react';

function LoginComponent() {

  const [data, setData] = useState('No Call');
  const [error, setError] = useState(null);

const handleLoginClick  = async () =>{
    setError(null);
    
    try {
      const response = await fetch('https://catfact.ninja/fact');

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const responseData = await response.json();
      setData(responseData.fact);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message);
    }
    };

  return (
    <>
    <p>{data}</p>
    <Form className='form-block'>
      <h2>Login</h2>
      <br/>
      <FloatingLabel
        controlId="floatingInput"
        label="Email"
        className="mb-3"
      >
      <Form.Control type="email" placeholder="Email" />
      </FloatingLabel>

      <FloatingLabel controlId="floatingPassword" label="Password">
        <Form.Control type="password" placeholder="Password" />
      </FloatingLabel>
      <br/>
      <Button variant="outline-info" size="lg" onClick={handleLoginClick}>
        LOGIN
      </Button>
    </Form>
    </>
  )
}

export default LoginComponent