import React from 'react';
import { Navbar, Container, Nav  } from 'react-bootstrap';

const BrandImg = require('../asserts/images/FMS_LOGO_NB.png');

function TopNavbar() {
  return (
    <>
      <Navbar bg='dark' className='navbar' variant='dark' expand='lg'>
        <Container>
          <Navbar.Brand href='/' >
          <img
              src={BrandImg}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="FMS"
            />
           
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id="basic-navbar-nav" className="me-auto justify-content-center">
          <Nav>
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default TopNavbar