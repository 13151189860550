import React from 'react';
import NotfoundComponent from '../components/NotfoundComponent';


function notFound() {
  return (
    <div>
        <NotfoundComponent />
    </div>
  )
}

export default notFound